<template>
	<div class="home">
		<div class="addList">
			<button @click="addDiscount">添加</button>
		</div>
		<!-- <div class="conList">
			<p>添加</p>
		</div> -->
		<van @click="toggle">
			<van-list v-model="isUpLoading" :finished="upFinished" :immediate-check="false" :offset="10"
				finished-text="我是有底线的" @load="onLoadList" style="padding:15px 15px 50px 15px;height: 100%;" v-if="goodsList.length != 0">
				<div v-for="(item,index) in goodsList" :key="index" class="goods-item" @click="goShopDetail(item)">
					<van-swipe-cell>
						<div class="left-dis">
							<div class="info-left">
								<img :src="imageUrl+item.image" alt="" class="goods-image">
								<div class="rig-rig">
									<p class="title">{{item.name}}</p>
									<p class="bankName">使用截止日期：{{item.end_time}}</p>
								</div>
								<div class="right-dis">
									<p class="today-res">今日剩余<span>{{item.remaining_rate}}</span>%</p>
									<div class="process">
										<div v-bind:style="{width: item.remaining_rate+'%'}"></div>
									</div>
									<p class="btn-ck">查看详情</p>
								</div>
							</div>
						</div>
						
						<template #right>
							<van-button square type="danger" text="删除" @click="delList(item.id)" />
						</template>
					</van-swipe-cell>
				</div>

			</van-list>
			<van-empty description="还没有优惠" v-else />
		</van>
	</div>
</template>
<script>
	import {
		index
	} from '@/api'
	import {
		toUrl
	} from '@/utils/tools'
	// import {
	// 	getIconList,
	//
	// } from '@/api/index'
	import {
		getShopList,
		couponDel
	} from '@/api/index'
	import
	http
	from '../../utils/config.js'
	export default {
		data() {
			return {
				images: [
					'https://img01.yzcdn.cn/vant/apple-1.jpg',
					'https://img01.yzcdn.cn/vant/apple-2.jpg',
				],
				shopType: '',
				nullTip: '空空如也~',
				limit: 10, // 每页条数
				page: 1, // 页码
				type: 1,
				goodsList: [], // 请求数据
				isDownLoading: false, // 下拉刷新
				isUpLoading: false, // 上拉加载
				upFinished: false, // 上拉加载完毕
				offset: 100, // 滚动条与底部距离小于 offset 时触发load事件
				health: 45,
				imageUrl: http.imageUrl,
			}
		},
		mounted() {
			// this.getShopType()
			this.getShopList()
		},
		methods: {
			goShopDetail(row) {
				this.$router.push({
					path: '/discountDetails',
					query: {
						id: row.id
					}
				})
			},
			toggle(name) {
				this.type = name + 1
				this.page = 1
				this.limit = 10
				this.goodsList = []
				this.getShopList()
			},
			// getShopType() {
			// 	getIconList().then((res) => {
			// 		console.log(res)
			// 		this.shopType = res.data
			// 	})
			// },
			delList(id){
				// console.log(id)
				let params = {}
				params.id = id
				couponDel(params).then((res) => {
					if (res.code == 200) {
						// console.log(res)
						this.getShopList()
					}
				})
			},
			// 添加
			addDiscount(){
				 this.$router.push('/addDiscount')
			},
			getShopList() {
				this.$toast.loading({
					message: '加载中...',
					forbidClick: true,
				});
				getShopList({
					page: this.page,
					limit: this.limit
				}).then((res) => {
					// console.log(res)
					const rows = res.data.data
					// console.log(rows.length)
					if (rows.length === 0) {
						// 加载结束
						this.upFinished = true
						return
					}
					if (rows.length < this.limit) {
						// 最后一页不足10条的情况
						this.upFinished = true
					}
					// 处理数据
					if (this.page === 1) {
						this.goodsList = rows
					} else {
						this.goodsList = this.goodsList.concat(rows)
					}
					// console.log(this.goodsList)
					this.$toast.clear()
				})
			},
			onDownRefresh() {

			},
			// 上拉加载请求方法
			onLoadList() {
				this.page++
				this.getShopList()
			},
		}
	}
</script>
<style lang="less" scoped>
	@import "~@/assets/style/preset.less";

	.van-swipe {
		width: 100%;
		height: 150px;
	}

	.shop-image {
		width: 100%;
		height: 100%;
	}

	.dis-banner {
		width: 90%;
		margin: 0 auto;

		img {
			width: 100%;
		}
	}
	.addList{
		height: 50px;
		line-height: 50px;
		button{
			width: 60px;
			height: 30px;
			line-height: 25px;
			font-size: 14px;
			border-radius: 5px;
			border: solid 2px #ee0a24;
			color: #ee0a24;
			background-color: #FFFFFF;
			float: right;
			margin-top: 15px;
			margin-right: 15px;
		}
	}

	.twoList {
		width: 90%;
		height: 60px;
		display: flex;
		justify-content: space-between;
		margin-top: 10px auto;

		.one {
			width: 48%;
			height: 100%;
			background-color: #fcd9c7;
			border-radius: 8px;
			display: flex;
			align-items: center;
			justify-content: space-around;

			.left-one {
				h6 {
					margin: 0;
					font-size: 12px;
					color: #f0181c;
				}

				p {
					margin: 0;
					font-size: 12px;
					width: 60px;
					height: 20px;
					line-height: 20px;
					text-align: center;
					border-radius: 15px;
					background: #fc8d4d;
					color: #FFFFFF;
					margin: 0 auto;
					margin-top: 5px;
				}
			}

			img {
				width: 50px;
				height: 50px;
			}
		}

		.two {
			width: 48%;
			height: 100%;
			background-color: #fabaae;
			border-radius: 8px;
			display: flex;
			align-items: center;
			justify-content: space-around;

			.left-one {
				h6 {
					margin: 0;
					font-size: 12px;
					color: #f0181c;
				}

				p {
					margin: 0;
					font-size: 12px;
					width: 60px;
					height: 20px;
					line-height: 20px;
					text-align: center;
					border-radius: 15px;
					background: #ff0303;
					color: #FFFFFF;
					margin: 0 auto;
					margin-top: 5px;
				}
			}

			img {
				width: 50px;
				height: 50px;
			}
		}
	}

	.conList {
		display: flex;
		width: 90%;
		height: 25px;
		padding: 5px 0;
		background-color: #FFFFFF;
		box-shadow: 0px 1px 5px 1px rgba(0, 0, 0, 0.15);
		border-radius: 5px;
		margin: 10px auto;

		div:nth-child(1) {
			width: 30%;
			justify-content: center;
			display: flex;
			align-items: center;
			border-right: solid 1px rgba(0, 0, 0, 0.15);

			img {
				width: 18px;
				height: 18px;
			}

			p {
				font-size: 12px;
				margin-left: 2px;
			}
		}

		div:nth-child(2) {
			width: 30%;
			justify-content: center;
			display: flex;
			align-items: center;
			border-right: solid 1px rgba(0, 0, 0, 0.15);

			img {
				width: 18px;
				height: 18px;
			}

			p {
				font-size: 12px;
				margin-left: 2px;
			}
		}

		div:nth-child(3) {
			width: 40%;
			justify-content: center;
			display: flex;
			align-items: center;
			border-right: solid 1px rgba(0, 0, 0, 0.15);

			img {
				width: 18px;
				height: 18px;
			}

			p {
				font-size: 12px;
				margin-left: 2px;
			}
		}
	}

	.home {
		min-height: calc(100vh);
		.options-area {
			display: flex;
			flex-wrap: wrap;
			background-color: #FFFFFF;

			.options {
				display: flex;
				align-items: center;
				flex-direction: column;
				width: 20%;
				margin-bottom: 10px;

				.options-icon {
					width: 55px;
					height: 55px;
				}

				.options-name {
					margin-top: 5px;
					font-size: 12px;
					color: #666666;
				}
			}
		}

		.goods-item {
			display: flex;
			align-items: flex-start;
			background-color: #FFFFFF;
			height: 88px;
			border-radius: 5px;
			margin-bottom: 10px;
			// padding: 5px 15px;
			justify-content: flex-start;

			.left-dis {
				font-size: 14px;
				width: 97%;
				height: 100%;
				padding: 5px 10px;

				p {
					margin: 0;
					padding: 0;

					span {
						color: #888;
						font-size: 10px;
						margin-left: 5px;
					}
				}

				.info-left {
					display: flex;
					align-items: center;
					height: 100%;
					.goods-image {
						width: 60px;
						height: 60px;
					}

					.rig-rig {
						margin-left: 15px;

						.title {
							font-size: 14px;
							color: #ee0a24;
							font-weight: 600;
						}

						.bankName {
							color: #555;
							font-size: 10px;
							margin-top: 8px;
						}
					}
				}
			}

			.right-dis {
				width: 30%;
				height: 100%;
				border-left: dotted 1px #ee0a24;
				background-color: #fdedee;
				display: flex;
				align-items: center;
				flex-direction: column;
				justify-content: center;

				.today-res {
					font-size: 12px;
					color: #ee0a24;
					margin: 0;
				}

				.process {
					width: 60px;
					height: 3px;
					// margin: 0 auto;
					margin: 5px;
					// border: black 4px solid;
					background-color: #ee0a24;

					div {
						height: 3px;
						background: #a80002;
					}
				}

				.btn-ck {
					font-size: 12px;
					border: solid 2px #ee0a24;
					color: #ee0a24;
					padding: 2px 3px;
					margin-top: 5px;
					border-radius: 5px;
				}
			}
		}
	}

	.van-swipe-cell {
		width: 100%;
		height: 100%;
	}
	.van-button{
		height: 100%;
	}
</style>
